// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://tdk888.kz:3001',
   
    // nameCargo: 'TURKESTAN KARGO'
    nameCargo: 'КАРГО 888'
  };
  
  
export default config;
